
<template>
 <div class="container">
  <!-- 头部 -->
    <div class="heard">
      <screendeilHead
        :title="title"
        :deviceOnline="deviceOnline"
        :deviceNo="deviceNo"
        :deviceType="deviceType"
      ></screendeilHead>
    </div>
    <!-- 主体 -->
    <div class="main">
      <div class="leftMain">
        <div class="leftTop">
          <div class="realBox">
          <load style="width: 100%" :realTimeData="realTimeData"></load>
          <MomentratioDel
            style="width: 100%"
            :realTimeData="realTimeData"
          ></MomentratioDel>
          <Slide style="width: 100%" :realTimeData="realTimeData"></Slide>
          <RotaTionDel
            style="width: 100%"
            :realTimeData="realTimeData"
          ></RotaTionDel>
          <HeightTionDel
            style="width: 100%"
            :realTimeData="realTimeData"
          ></HeightTionDel>
          <LuffingDel
            style="width: 100%"
            :realTimeData="realTimeData"
          ></LuffingDel>
          <WindDel style="width: 100%" :realTimeData="realTimeData"></WindDel>
          <Horizontal
            style="width: 100%"
            :realTimeData="realTimeData"
          ></Horizontal>
        </div>
        </div>
        <div class="leftModel">
          <div class="leftdel">
            <div class="henxian">
              <div class="box8">
                <div class="box9">
                  <div>
                    <img src="../../../assets/images/screen/sanjiaoxin.png" alt="">
                  </div>
                  <div class="title">
                    数据模拟
                  </div>
                </div>
                <div  class="box10">
                  <img src="../../../assets/images/screen/duobianx.png" alt="">
                </div>
              </div>
              <div class="henxianbox">
              
              </div>
              
            </div>
            <div class="simulatedBox">
              <simulated
              :title="title"
              :deviceType="deviceType"
              :realTimeData="realTimeData"
              :workData="workData"
            ></simulated>
            </div>
            <div class="warnBoxsc">
              <div class="singular" v-for="(item, index) in list" :key="index">
              <div
                class="showBox"
                :class="[item.show == true ? 'showBox2' : 'showBox']"
              >
              <div class="test">
                    <div v-if="item.show == false">
                      <img
                        src="../../../assets/images/screen/newnormal.png"
                        alt=""
                      />
                    </div>
                    <div v-if="item.show == true">
                      <img
                        src="../../../assets/images/screen/newpolice.png"
                        alt=""
                      />
                    </div>
                    <div>{{ item.name }}</div>
                  </div>
              </div>
            </div>
            </div>
           
          </div>
          <div class="leftdel2 video" ref="videoRef">
            <div class="henxian">
              <div class="box8">
                <div class="box9">
                  <div>
                    <img src="../../../assets/images/screen/sanjiaoxin.png" alt="">
                  </div>
                  <div class="title">
                    监控视频
                  </div>
                </div>
                <div  class="box10">
                  <img src="../../../assets/images/screen/duobianx.png" alt="">
                </div>
              </div>
              <div class="henxianbox">
              
              </div>
              
            </div>
            <div class="tabs">
              <el-tabs v-model="activeName" @tab-click="handleClick">
                <el-tab-pane
                  :label="item.cameType"
                  :name="item.cameSn"
                  v-for="(item, index) in videoList"
                  :key="index"
                >
                </el-tab-pane>
              </el-tabs>
            </div>
           
            <div class="videoss">
              <ScreenVideo
                :data="videoInfo"
                :height="videoHeight"
                :width="videoWidth"
                :index="2"
                class=""
              ></ScreenVideo>
            </div>
          </div>
        </div>
        <div class="leftBottom">
          <div class="leftBottomdel">
            <div class="henxian">
              <div class="box8">
                <div class="box9">
                  <div>
                    <img src="../../../assets/images/screen/sanjiaoxin.png" alt="">
                  </div>
                  <div class="title">
                    实时数据
                  </div>
                </div>
                <div  class="box10">
                  <img src="../../../assets/images/screen/duobianx.png" alt="">
                </div>
              </div>
              <div class="henxianbox">
              
              </div>
              
            </div>
               <div class="realDatadetailbox">
                <realDatadetail
                 :title="title"
                 :realTimeData="realTimeData"
                 :times="times"
               ></realDatadetail>
               </div>
          </div>
          <div class="leftBottomdel2">
            <div class="henxian">
              <div class="box8">
                <div class="box9">
                  <div>
                    <img src="../../../assets/images/screen/sanjiaoxin.png" alt="">
                  </div>
                  <div class="title">
                    工况数据
                  </div>
                </div>
                <div  class="box10">
                  <img src="../../../assets/images/screen/duobianx.png" alt="">
                </div>
              </div>
              <div class="henxianbox">
              
              </div>
              
            </div>
            <div class="workSetData">
              <workSetData :workData="workData"></workSetData>
            </div>
          </div>
        </div>
      </div>
      <div class="rightMain">
        <div class="rightTop">
          <userInfo :projId="projId" :title="title"></userInfo>
        </div>
        <div class="rightBottom" ref="rightBottomref">
          <div class="henxian">
              <div class="box8">
                <div class="box9">
                  <div>
                    <img src="../../../assets/images/screen/sanjiaoxin.png" alt="">
                  </div>
                  <div class="title">
                    今日告警
                  </div>
                </div>
                <div  class="box10">
                  <img src="../../../assets/images/screen/duobianx.png" alt="">
                </div>
              </div>
              <div class="henxianbox">
              
              </div>
              
            </div>
            <div class="warndetail">
              <detailToDayWarn :title="title" :tableHeight="tableHeight"></detailToDayWarn>
            </div>
         
        </div>
      </div>
    </div>
 </div>
</template>

<script>
import screendeilHead from "../../../components/screen/screendeilHead.vue";
import load from "../../../components/screen/load.vue";
import MomentratioDel from "../../../components/screen/MomentratioDel.vue";
import realDatadetail from "../../../components/screen/realDatadetail.vue";
import simulated from "../../../components/screen/simulated.vue";
import ScreenVideo from "../../../components/screen/ScreenVideo.vue";
import userInfo from "../../../components/screen/userInfo.vue";
import workSetData from "../../../components/screen/workSetData.vue";
import detailToDayWarn from "../../../components/screen/detailToDayWarn.vue";
import Slide from "../../../components/screen/SlideDel.vue";
import RotaTionDel from "../../../components/screen/RotaTionDel.vue";
import HeightTionDel from "../../../components/screen/HeightTionDel.vue";
import LuffingDel from "../../../components/screen/LuffingDel.vue";
import WindDel from "../../../components/screen/WindDel.vue";
import Horizontal from "../../../components/screen/HorizontalDel.vue";
import screen from "../../../api/modules/screen";
export default {
  components: {
    screendeilHead,
    load,
    MomentratioDel,
    realDatadetail,
    simulated,
    ScreenVideo,
    userInfo,
    workSetData,
    detailToDayWarn,
    Slide,
    RotaTionDel,
    HeightTionDel,
    LuffingDel,
    WindDel,
    Horizontal,
  },
  data() {
    return {
      timer: null,
      timer2: null,
      list:[
      { name: "左禁行区", id: 18, show: false, type: "anticollision" },
        { name: "远禁行区", id: 18, show: false, type: "anticollision" },
        { name: "高限位", id: 18, show: false, type: "anticollision" },
        { name: "远限位", id: 18, show: false, type: "anticollision" },
        { name: "左限位", id: 18, show: false, type: "anticollision" },
      { name: "重量", id: 1, show: false, type: "weight" },
        { name: "力矩", id: 2, show: false, type: "moment" },
        { name: "高度", id: 3, show: false, type: "height" },
        { name: "变幅", id: 4, show: false, type: "amplitude" },
        { name: "旋转", id: 5, show: false, type: "rotate" },
      
        { name: "右禁行区", id: 18, show: false, type: "anticollision" },
        { name: "近禁行区", id: 18, show: false, type: "anticollision" },
        { name: "低限位", id: 18, show: false, type: "anticollision" },
        { name: "近限位", id: 18, show: false, type: "anticollision" },
        { name: "右限位", id: 18, show: false, type: "anticollision" },
        { name: "风速", id: 7, show: false, type: "wind" },
        { name: "行走", id: 8, show: false, type: "walk" },
        { name: "障碍物", id: 9, show: false, type: "barrier" },
        { name: "防碰撞", id: 10, show: false, type: "anticollision" },
        { name: "仰角", id: 6, show: false, type: "elevation" },
      ],
      // list: [
       
      //   { name: "左禁行区", id: 11, show: false, type: "anticollision" },
      //   { name: "远禁行区", id: 12, show: false, type: "anticollision" },
      //   { name: "高限位", id: 12, show: false, type: "anticollision" },
      //   { name: "远限位", id: 12, show: false, type: "anticollision" },
      //   { name: "左限位", id: 12, show: false, type: "anticollision" },
      //   { name: "倾斜", id: 12, show: false, type: "anticollision" },
      //   { name: "风速", id: 12, show: false, type: "anticollision" },
      //   { name: "左碰撞", id: 12, show: false, type: "anticollision" },
      //   { name: "右碰撞", id: 12, show: false, type: "anticollision" },
      //   { name: "右禁行区", id: 12, show: false, type: "anticollision" },
      //   { name: "近禁行区", id: 12, show: false, type: "anticollision" },
      //   { name: "低限位", id: 12, show: false, type: "anticollision" },
      //   { name: "近限位", id: 12, show: false, type: "anticollision" },
      //   { name: "右限位", id: 12, show: false, type: "anticollision" },
      //   { name: "载重", id: 12, show: false, type: "anticollision" },
      //   { name: "低碰撞", id: 12, show: false, type: "anticollision" },
      //   { name: "右碰撞", id: 12, show: false, type: "anticollision" },
      //   { name: "近碰撞", id: 12, show: false, type: "anticollision" },
      // ],

      value: "",
      projId: "",
      title: "",
      deviceType: "",
      videoInfo: {
        type: 0,
        url: "E60695771", //E60695771
      },
      videoHeight: 0,
      videoWidth: 0,
      tableHeight:0,
      activeName: "",
      videoList: [],
      projId: "",
      realTimeData: [],
      times: "",
      workData: {},
      videoType: "",
      deviceOnline: "",
      deviceId: "",
      deviceNo: "",
    };
  },
  beforeDestroy() {
    // 清除定时器
    clearInterval(this.timer);
    clearInterval(this.timer2);
  },
  mounted() {
    const query = this.$route.query;
    console.log("query", query);
    this.typeDel = {
      title: query.title,
      deviceType: query.deviceType,
    };
    this.deviceId = query.deviceId;
    this.projId = query.projId;
    this.deviceType = query.deviceType;
    this.title = query.title;
    this.deviceOnline = query.deviceOnline;
    this.videoType = query.type;
    this.deviceNo = query.deviceNo;
    this.getVideoList(this.title, query.type);
    this.getRealDataList(this.title);
    this.getDeviceParam(this.deviceId);
    this.getSelectDeviceAlarm(this.title);
    this.timer = setInterval(() => {
      this.getRealDataList(this.title);
      this.getSelectDeviceAlarm(this.title);
    }, 2000);
    this.timer2 = setInterval(() => {
      this.getDeviceParam(this.deviceId);
    }, 2000);
    // this.videoInfo = {
    //   type: query.type,
    //   url: query.url,
    // };
    this.videoHeight = this.$refs.videoRef.clientHeight + 40;
    this.tableHeight = this.$refs.rightBottomref.clientHeight- 50
    this.videoWidth = this.$refs.videoRef.clientWidth - 20;
    console.log(this.videoHeight, this.videoWidth);
  },
  methods: {
    handleClick(tab, event) {
      screen.getListCamera({ deviceSn: this.title }).then((res) => {
        console.log("res", res);
        res.data.map((item) => {
          if (item.cameSn == tab.name) {
            this.videoInfo = {
              type: item.cameraType,
              url: tab.name,
            };
          }
        });
        // this.videoList = res.data;
        // this.activeName = res.data[0].cameSn;
        // this.videoInfo = {
        //   type: res.data[0].cameraType,
        //   url: res.data[0].cameSn,
        // };
      });
    },
    getVideoList(deviceSn, type) {
      screen.getListCamera({ deviceSn: deviceSn }).then((res) => {
        console.log("resxxxxxxxxxxx", res);
        this.videoList = res.data;
        this.activeName = res.data[0].cameSn;
        this.videoInfo = {
          type: res.data[0].cameraType,
          url: res.data[0].cameSn,
        };
      });
    },
    getRealDataList(deviceImei) {
      let params = {
        deviceImei: deviceImei,
      };
      screen
        .realDataList(params)
        .then((res) => {
          console.log("实时数据", res);
          if (res.data) {
            let jsonData = JSON.parse(res.data);
            this.times = jsonData.timestamp;
            console.log("jsonData", jsonData);
            this.realTimeData = jsonData.data;
            console.log("this.realTimeData", this.realTimeData);
          } else {
            this.times = 1;
            this.realTimeData = {};
            // clearInterval(this.timer);
          }
        })
        .catch((err) => {});
    },
    getDeviceParam(deviceId) {
      let params = {
        deviceId: deviceId,
        paramLable: "workset",
      };
      screen
        .deviceParam(params)
        .then((res) => {
          let jsonData = JSON.parse(res.data.paramContent);

          this.workData = jsonData.workset;
        })
        .catch((err) => {});
    },
    updateListByType(data, type) {
      data.map((item) => {
        if (item.alarmLevel >= 12) {
          this.list.find((item2) => item2.type === type).show = true;
        }
      });
    },
    getSelectDeviceAlarm(deviceImei) {
      // const typeMap = {
      //   amplitude: "amplitude",
      //   weight: "weight",
      //   moment: "moment",
      //   anticollision: "anticollision",
      //   barrier: "barrier",
      //   elevation: "elevation",
      //   height: "height",
      //   rotate: "rotate",
      //   walk: "walk",
      //   wind: "wind",
        
      // };
         const typeMap = {
        amplitude: "amplitude",
        weight: "weight",
        moment: "moment",
        anticollision: "anticollision",
        barrier: "barrier",
        elevation: "elevation",
        height: "height",
        rotate: "rotate",
        walk: "walk",
        wind: "wind",
        
      };
      screen.selectDeviceAlarm({ deviceImei: deviceImei }).then((res) => {
        console.log("res", res);
        for (const key in typeMap) {
          if (res.data[key]) {
            this.updateListByType(res.data[key], typeMap[key]);
          }
        }
      });
    },
  },
};
</script>

<style lang='less' scoped>
.container {
  background-image: url("../../../assets/images/screen/newbgdel.png");
  background-repeat: no-repeat;
  background-size: 100% 100%;
  height: 100%;
  background-position: center;
  padding: 0 20px;
  position: relative;
  overflow: auto;
}
.heard {
  height: 6%;
  // border: 1px solid red;
}
.main {
  height: 94%;
  // border: 1px solid red;
  display: flex;
}
.leftMain {
  width: 79%;
  height: 100%;
  margin-right: 1%;
  // border: 1px solid red;
  .leftTop {
    height: 20%;
    // border: 1px solid red;
    .realBox {
      height: 100%;
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: space-between;
    }
  }
  .leftModel {
    height: 50%;
    // border: 1px solid red;
    display: flex;
    .leftdel {
      width: 49%;
      height: 100%;
      overflow: auto;
      // border: 1px solid red;
      overflow: auto;

      .henxian{
        height: 6%;
        color: #ffffff;
        // border: 1px solid red;
       .box8{
        display: flex;
        justify-content: space-between;
        position: relative;
        // align-items: end;
        height: 94%;
        .box9{
          display: flex;
          .title{
            margin-left: 10px;
          }
        }
        .box10{
          position: absolute; bottom: 0; right: 0;
        }
       }
        .henxianbox{
          background-image: url("../../../assets/images/screen/hengxian.png");
        background-repeat: no-repeat;
        background-size: 100% 100%;
        height: 6%;
        // border: 1px solid red;
        }
      }
      .simulatedBox {
        height: 74%;
        overflow: auto;
      }
      .warnBoxsc {
        height: 18%;
        // border: 1px solid red;
        background-image: url("../../../assets/images/screen/userInfo.png");
        background-repeat: no-repeat;
        background-size: 100% 100%;
        overflow: hidden;
        display: flex;
        padding: 0 20px;
        align-items: center;
        flex-wrap: wrap;
        .singular {
          width: 11%;
        }
        .showBox {
          color: #ffffff;
        }
        .showBox2 {
          color: red;
        }
        .test {
          display: flex;
          // border: 1px solid red;
        }
      }
    }
    .leftdel2 {
      width: 49%;
      height: 100%;
      margin-left: 2%;
      // border: 1px solid red;
      .videoTitleBox {
        background-image: url("../../../assets/images/screen/newTitleBox.png");
        background-repeat: no-repeat;
        background-size: 100% 100%;
        height: 8%;
        // margin-bottom: 3%;
        background-position: center;
        color: #ffffff;
        padding-left: 20px;
      }
      .henxian{
        height: 6%;
        color: #ffffff;
        // border: 1px solid red;
       .box8{
        display: flex;
        justify-content: space-between;
        position: relative;
        // align-items: end;
        height: 94%;
        .box9{
          display: flex;
          .title{
            margin-left: 10px;
          }
        }
        .box10{
          position: absolute; bottom: 0; right: 0;
        }
       }
        .henxianbox{
          background-image: url("../../../assets/images/screen/hengxian.png");
        background-repeat: no-repeat;
        background-size: 100% 100%;
        height: 6%;
        // border: 1px solid red;
        }
      }
      .tabs{
        height: 10%;
      }
      .videoss {
        // margin-top: 3%;
        height: 82%;
        // padding: 16px;
        overflow: auto;
      }
    }
  }
  .leftBottom {
    height: 30%;
    // border: 1px solid yellow;
    display: flex;
    .leftBottomdel {
      width: 49%;
      height: 100%;
      .simulationBg {
        background-image: url("../../../assets/images/screen/newTitleBox.png");
        background-repeat: no-repeat;
        background-size: 100% 100%;
        height: 14%;

        background-position: center;
        color: #ffffff;
        padding-left: 20px;
      }
      .henxian{
        height: 12%;
        color: #ffffff;
        // border: 1px solid red;
       .box8{
        display: flex;
        justify-content: space-between;
        position: relative;
        // align-items: end;
        height: 94%;
        .box9{
          display: flex;
          .title{
            margin-left: 10px;
          }
        }
        .box10{
          position: absolute; bottom: 0; right: 0;
        }
       }
        .henxianbox{
          background-image: url("../../../assets/images/screen/hengxian.png");
        background-repeat: no-repeat;
        background-size: 100% 100%;
        height: 6%;
        // border: 1px solid red;
        }
      }
      // border: 1px solid red;
      .realDatadetailbox {
        height: 88%;
      }
    }
    .leftBottomdel2 {
      width: 49%;
      margin-left: 2%;
      height: 100%;
      // border: 1px solid red;
      .simulationBg {
        background-image: url("../../../assets/images/screen/newTitleBox.png");
        background-repeat: no-repeat;
        background-size: 100% 100%;
        height: 100%;

        background-position: center;
        color: #ffffff;
        padding-left: 20px;
        display: flex;
        justify-content: space-between;
        // align-items: center;
        padding-right: 20px;
      }
      .henxian{
        height: 12%;
        color: #ffffff;
        // border: 1px solid red;
       .box8{
        display: flex;
        justify-content: space-between;
        position: relative;
        // align-items: end;
        height: 94%;
        .box9{
          display: flex;
          .title{
            margin-left: 10px;
          }
        }
        .box10{
          position: absolute; bottom: 0; right: 0;
        }
       }
        .henxianbox{
          background-image: url("../../../assets/images/screen/hengxian.png");
        background-repeat: no-repeat;
        background-size: 100% 100%;
        height: 6%;
        // border: 1px solid red;
        }
      }
      .workSetData {
        height: 88%;
        // margin-top: 10%;
      }
    }
  }
}
.rightMain {
  // margin-left: 2%;
  width: 19%;
  height: 100%;
  // border: 1px solid red;
  .rightTop {
    height: 40%;
    // border: 1px solid red;
  }
  .rightBottom {
    height: 60%;
    // border: 1px solid red;
    .simulationBg {
      background-image: url("../../../assets/images/screen/newTitleBox.png");
      background-repeat: no-repeat;
      background-size: 100% 100%;
      height: 100%;
      // width: 140%;
      background-position: center;
      color: #ffffff;
      padding-left: 20px;
      display: flex;
      justify-content: space-between;
      // align-items: center;
      padding-right: 20px;
    }
    .henxian{
        height: 5%;
        color: #ffffff;
        // border: 1px solid red;
       .box8{
        display: flex;
        justify-content: space-between;
        position: relative;
        // align-items: end;
        height: 94%;
        .box9{
          display: flex;
          .title{
            margin-left: 10px;
          }
        }
        .box10{
          position: absolute; bottom: 0; right: 0;
        }
       }
        .henxianbox{
          background-image: url("../../../assets/images/screen/hengxian.png");
        background-repeat: no-repeat;
        background-size: 100% 100%;
        height: 6%;
        // border: 1px solid red;
        }
      }
    .warndetail {
      height: 95%;
    }
  }
}
/deep/.el-tabs__item {
  padding: 0 20px;
  height: 40px;
  box-sizing: border-box;
  line-height: 40px;
  display: inline-block;
  list-style: none;
  font-size: 14px;
  font-weight: 500;
  color: #ffffff;
  position: relative;
}
/deep/.el-tabs__item.is-active {
  color: #409eff; /* Default text color for unselected tabs */
}
</style>
