<template>
    <component :is="vidoTYpeObj[deviceType]" :title="title" :realTimeData="realTimeData" :workData="workData"> </component>
  </template>
  
  <script>
  import tower from "../../components/screen/simulated/tower.vue";
  import YinshiVideo from "./YinshiVideo";
  import ZiyouVideo from "./ZiyouVideo";
  export default {
    name: "index",
    props: ["deviceType", "title", "width", "height",'realTimeData','workData'],
    components: {
        tower,
      ZiyouVideo,
      YinshiVideo,
    },
    watch: {
      data(val) {
        console.log(val);
      },
    },
    data() {
      return {
        MonitorSwitchpageOR: "",
        vidoTYpeObj: {
          0: "tower",
          1: "tower",
          2: "tower",
        },
      };
    },
  };
  </script>
  
  <style scoped></style>
  