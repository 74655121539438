
<template>
  <div class="main-box">
    <div class="chart-box" ref="monthWarn"></div>
  </div>
</template>

<script>
import * as echarts from "echarts";

export default {
  components: {},
  props:['realTimeData',],
  data() {
    return {
      chartMonthWarn: null,
    };
  },
  mounted() {
    // this.initCarTrendChar();
  },
  watch: {
    realTimeData: {
      handler(val) {
       if(val.wind){
       
        this.run(val.wind);
       }else{
        this.run(0);
       }
      },
      
    },
    
  },
  methods: {
    setGaugeOption( ruler, val) {
   
    ruler = ruler.sort((a, b) => a - b);
    let chartMaxVal = 100000; //仪表盘对应0--100的最大刻度，越大越精确
    let unitStep = chartMaxVal / (ruler.length - 1);

    //将仪表盘数据转换为实际数据  0--chartMaxVal 范围 到刻度范围的转换
    let valConvert = (value) => {
      for (let i = 0; i < ruler.length - 1; i++) {
        if (value <= (i + 1) * unitStep) {
          let lastRuler = ruler[i];
          let rulerSpan = ruler[i + 1] - ruler[i];
          let valSpan = value - i * unitStep;
          valSpan = (valSpan * rulerSpan) / unitStep;
          return Math.round(lastRuler + valSpan);
        }
      }
      return ruler[ruler.length - 1];
    };

    let valText = (value) => {
      return `${val}\n风速`;
    };

    //将实际数据转换为仪表盘数据 刻度范围到 0--chartMaxVal 范围的转换
    let getGaugeData = (value) => {
      if (value <= ruler[0]) return 0;
      for (let i = 1; i < ruler.length; i++) {
        if (value <= ruler[i]) {
          let startVal = unitStep * (i - 1);
          let rulerSpan = ruler[i] - ruler[i - 1];
          let lastRuler = ruler[i - 1];
          let ret = startVal + ((value - lastRuler) / rulerSpan) * unitStep;
          return Math.round(ret);
        }
      }
      return chartMaxVal;
    };

    let gaugeData = getGaugeData(val);

    let option = {
      series: [
        {
          name: "test speed",
          type: "gauge",
          radius: "70%",
          splitNumber: ruler.length - 1, //仪表盘平均分配分段刻度
          max: chartMaxVal,
          
          axisLine: {
            lineStyle: {
              width: 10,
              color: [
                 [0.95, "#53ffa4"],
                [0.975, "#fddd60"],
                [1, "#ff6e76"],
              ],
            },
          },
          pointer: {
          width: 4, // Adjust the width of the pointer
          length: '60%', // Adjust the length of the pointer
          itemStyle: {
            color: 'auto',
          },
        },
          splitLine: {
            distance: -10,//长刻度尺的距离
            length: 10,
            lineStyle: {
              color: "#fff",
              width: 3,
            },
          },
          axisTick: {
            distance: -10,
            length: 8,
            lineStyle: {
              color: "#fff",
              width: 2,
            },
          },
          axisLabel: {
            color: "auto",
            show: false,
            distance: 15,
            fontSize: 10,
            //仪表盘刻度设置
            formatter: valConvert,
          },
          detail: {
            fontSize: 12,
            formatter: valText,
            offsetCenter: [0, "50%"],
            valueAnimation: true,
            color: "auto",
          },
          data: [
            {
              value: gaugeData,
            },
          ],
        },
      ],
    };
    if (!this.chartMonthWarn) {
      this.chartMonthWarn = echarts.init(this.$refs.monthWarn, "chalk");
    }

    // const option = getLoads(dataArr, colorSet,color1,color2,name);

    this.chartMonthWarn.setOption(option);

  
  },
  run(row) {
    let ruler = [0,10, 20, 30,40, ]; //仪表盘刻度
    //  let maxVal = Math.ceil(row / 10) * 10; 
    //  console.log('maxVal',maxVal);
     
    // ruler.push(maxVal+10); 
    // let val = 1865.25;
      this.setGaugeOption( ruler, row);
  
  },
  },
};
</script>

<style lang="less" scoped>
.main-box {
  height: 100%;
  .chart-box {
    height: 100%;
    // height: 230px;
    // width: 600px;
  }
}
</style>
