<template>
  <div class="headDeil">
    <div class="bgdels">
      <div class="bgdelLeft">
        <div class="title">{{ deviceNo }}</div>
        <div>监控主机：{{ title }}</div>
        <div class="iconBox" v-if="onLine == 0">
          <img src="../../assets/images/screen/newonlie.png" alt="" />
          <span class="marinLeft">在线</span>
        </div>
        <div class="iconBox" v-if="onLine == 1">
          <img src="../../assets/images/screen/off.png" alt="" />
          <span class="marinLeft">离线</span>
        </div>
        <div class="iconBox">
          <img src="../../assets/images/screen/newnormal.png" alt="" />
          <span class="marinLeft">正常</span>
        </div>
        <div class="iconBox">
          <img src="../../assets/images/screen/newearly.png" alt="" />
          <span class="marinLeft">预警</span>
        </div>
        <div class="iconBox">
          <img src="../../assets/images/screen/newpolice.png" alt="" />
          <span class="marinLeft">报警</span>
        </div>
      </div>
      <div class="bgdelRight">
        <div class="boderbg">
          <img src="../../assets/images/screen/time.png" alt="" />
          <div class="timeTitle">上线时间:{{ onLineTime }}</div>
        </div>
        <div class="boderbg workTime">
          <img src="../../assets/images/screen/time.png" alt="" />
          <div class="timeTitle">工作循环时长:{{days}}天{{hours}}时{{minutes}}分{{seconds}}秒</div>
        </div>
        <div class="back" @click="back">返回</div>
      </div>
     
    </div>
  </div>
</template>

<script>
import screen from "../../api/modules/screen";
export default {
  props: ["title", "deviceOnline",'deviceNo'],
  components: {},
  data() {
    return {
      onLine: "",
      timer: null,
      onLineTime: "",
      days:'',
      hours:'',
      minutes:'',
      seconds:''
    };
  },
  mounted() {},
  watch: {
    title(val) {
      this.getDeviceOnlie(val);
      this.getSelectDeviceTime(val);
      this.timer = setInterval(() => {
        this.getDeviceOnlie(val);
        this.getSelectDeviceTime(val);
      }, 10000);
    },
  },
    beforeDestroy() {
    // 清除定时器
    clearInterval(this.timer);
   
  },
  methods: {
    getDeviceOnlie(deviceImei) {
      screen.selectOnline(deviceImei).then((res) => {
     
        this.onLine = res.data.deviceOnline;
      });
    },
    back(){
      this.$router.go(-1);
    },
    getSelectDeviceTime(deviceImei) {
      screen.selectDeviceTime(deviceImei).then((res) => {
       
        this.onLineTime = res.data.onLineTime;
        // Timestamps in milliseconds
        const startTime = new Date(res.data.startTime).getTime();
        const endTime = new Date(res.data.endTime).getTime();

        // Calculate the difference in milliseconds
        let timeDiff = endTime - startTime;

        // Calculate days, hours, minutes, and seconds
        this.seconds = Math.floor(timeDiff / 1000);
        this.minutes = Math.floor(this.seconds / 60);
        this.hours = Math.floor(this.minutes / 60);
        this.days = Math.floor(this.hours / 24);

        this.hours %= 24;
        this.minutes %= 60;
        this.seconds %= 60;

       
      });
    },
  },
};
</script>

<style lang="less" scoped>
.headDeil {
  height: 100%;
}
.bgdels {
  background-image: url("../../assets/images/screen/newheads.png");
  background-repeat: no-repeat;
  background-size: 100% 100%;
  height: 100%;
  //   line-height: 55px;
  background-position: center;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.boderbg {
  // background-image: url("../../assets/images/screen/boder.png");
  background-repeat: no-repeat;
  background-size: 100% 100%;
  height: 100%;
  //   width: 240px;
  font-size: 14px;
  // padding: 7px 15px;
  // margin-top: 5px;
  //   line-height: 55px;
  padding: 0 15px;
  color: #78f0fd;
  background-position: center;
  display: flex;
  justify-content: center;
  align-items: center;
}
.bgdelLeft {
  display: flex;
  color: #ffffff;
  align-items: center;
  justify-content: space-between;
}
.marinLeft {
  margin-left: 12px;
  font-size: 14px;
}
.iconBox {
  margin-left: 19px;
  display: flex;
  align-items: center;
}
.title {
  margin-right: 15px;
  font-size: 18px;
}
.bgdelRight {
  display: flex;
  height: 100%;
}
.timeTitle {
  margin-left: 6px;
}
.workTime {
  margin-left: 21px;
}
.back{
  cursor: pointer;
  color: #78f0fd;
  display: flex;
  align-items: center;
}
</style>
