import { render, staticRenderFns } from "./WindDel.vue?vue&type=template&id=2609b929&scoped=true"
import script from "./WindDel.vue?vue&type=script&lang=js"
export * from "./WindDel.vue?vue&type=script&lang=js"
import style0 from "./WindDel.vue?vue&type=style&index=0&id=2609b929&prod&lang=less&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2609b929",
  null
  
)

export default component.exports