<!--  -->
<template>
  <div style="height: 100%">
    <div class="userBg">
   
      <div class="imgs">
        <!-- <img :src="data.operFaceUrl" alt="" style="width: 50%; height: 100%;" /> -->
        <el-avatar shape="square" :size="100" fit="cover" :src="data.operFaceUrl"></el-avatar>
      </div>
      <div class="box"></div>
      <div class="infos">
        <div class="infosbox">电&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; 话：{{ data.operMobile }}</div>
        <div class="infosbox">身&nbsp;份&nbsp;证&nbsp;：{{ data.operIdcard }}</div>
        <div class="infosbox">证&nbsp;件&nbsp;号&nbsp;：{{ data.cardNo }}</div>
        <div class="infosbox">认证时间：{{ data.startTime }}</div>
      </div>
     
    </div>
  </div>
</template>

<script>
import screen from "../../api/modules/screen";
export default {
  components: {},
  props: ["projId",'title'],
  data() {
    return {
      data: "",
    };
  },
  mounted() {},
  watch: {
    title(val) {
      console.log("vaxxxl", val);

      this.init(val);
    },
  },
  methods: {
    init(val) {
      screen.getPageList({ deviceImei: val   }).then((res) => {
      
        this.data = res.data;
      });
    },
  },
};
</script>

<style lang="less" scoped>
.userBg {
  // background-image: url("../../assets/images/screen/userInfo.png");
  // background-repeat: no-repeat;
  // background-size: 100% 100%;
  height: 100%;
  background-position: center;
  width: 100%;
  // display: flex;
  // align-items: center;
  // justify-content: space-between;
  font-size: 12px;
  padding: 0 10px;
  color: #ffffff;
  overflow: auto;
  // text-align: center;
  // border: 1px solid red;
  position: relative;
}
.imgs{
  display: flex;
  justify-content: center;
  align-items: center;
  height: 38%;
  width: 100%;
  padding: 2% 0 0 0 ;
  // border: 1px solid red;
  // border-radius: 50%;
}
.infos{
  height: 50%;
  width: 80%;
  position: absolute;
  left: 50%;

  transform: translateX(-50%);
  // padding: 16px;
  // border: 1px solid red;
}
.infosbox{
  height: 25%;
  // font-size: 16px;
}
.box{
  height: 10%;
}
</style>
